import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css'
import {Helmet} from "react-helmet";


export default function Portview() {
  return (
    <Layout pageTitle="Portview">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Portview Belfast - USI Projects</title>
        <meta name="description" content="Urban Scale Interventions have worked with the charitable organisation who owns Portview to bring the whole site into use, through establishing an Education, Tourism, Resilience, Heritage and Employment masterplan."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>


      <section class="text-gray-700 body-font heading-container-portview">
        <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Portview</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                Portview Trade Centre</p>
              </div>
          </section>
        </div>
      </section>
      
      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-6 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-16 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-blue-300">OVERVIEW</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">
                Portview
                  Trade Centre is located to the East of Belfast on the Newtownards Road, just a 10-minute drive from
                  Belfast City Centre. Originally a spinning mill built in the early 1900s, it was ahead of its time in
                  both its employment of women and the inclusion of both traditional creative and innovative
                  technologies. Once a key feature of a vibrant thriving community, this geographical area is now one of
                  the most deprived in Northern Ireland, with high rates of poverty and only 8% of people aged 16 and
                  over continuing onto further education.</p>
              </div>
              <div class="md:w-1/4 py-4 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Climate<br/>
                  Mental Health<br/>Resilience<br/>Connectivity<br/>Tourism<br/>Technology</p>
                <p class="text-2xl font-bold uppercase mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Policy Scoping<br/>
                  Market Analysis<br/>Stakeholder Consultation<br/>Public Engagement<br/>Strategic Vision<br/>Design
                  Strategy<br/>Project Management</p>
                <p class="text-2xl font-bold uppercase mt-8">Visit</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0"><a target="_blank" href='http://portviewbelfast.org'>portviewbelfast.org</a></p>
              </div>
            </div>
          </section>
        </div>
      </section>







      <div class="flex flex-wrap md:mb-0 md:mt-16">
        <div class="h-auto w-full md:w-1/2 md:mb-0">
          <img class="w-full h-full px-2 py-2" src="https://ik.imagekit.io/usi/project-pages/portview-left_2Ta76re1D.png"
            alt="" width="700px"/></div>
        <div class="h-auto w-full px-2 py-2 md:w-1/2 md:mb-0">
          <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/portview-right_KygiDZI0EtYg.png" alt=""
            width="700px"/>
        </div>
      </div>


      <div class="md:p-16 md:m-16">
        <video controls
            src="https://player.vimeo.com/external/504353529.hd.mp4?s=a5dbf47b4e56993fd9b1ce9d45da9aa29229d40e&profile_id=175"/>
      </div>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pb-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="w-full md:px-40 xl:w-3/5 py-4 xl:px-32 mb-4 md:mb-0">
                <p 
                  class="text-xl md:text-2xl  md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16">
                  Through the adoption of a social tenancy and addition of a project-based learning education hub to the
                  site, the ambition is to become a leading example of how heritage spaces can become carbon-neutral
                  sites and embed social value. Our long-term ambition within the project is to develop the spaces on
                  the site. By fully occupying the buildings on-site with projects involving specific social objectives
                  and innovative development with education and training outputs we hope to build a culture of
                  investment into the local community, and into Belfast as a whole through education and industry
                  development.<br/><br/>

                  The initial work and forward thinking plans has recently been recognised with a large Heritage Lottery
                  Grant to further develop the plans and ambitions for the site.

                </p>


              </div>
              <div
                class="self-end w-full md:w-9/12 xl:w-2/5 py-4 md:pb-36 sm:px-8 md:px-24 mb-4 md:mb-0">
                <img src="https://ik.imagekit.io/usi/project-pages/nlhf_oreiKGOGRQQ4.svg" alt="heritage fun"/>

              </div>
            </div>
          </section>
        </div>
      </section>






      <img class="w-full py-4" src="https://ik.imagekit.io/usi/home/portview-timeline_ElVRWddXL.svg" alt=""/>

      <img data-scroll data-scroll-offset="200" data-scroll-speed="2" data-scroll-class="scroll-class"
        class="w-full py-4 md:pl-20 md:pr-20 md:pt-20 md:pb-20"
        src="https://ik.imagekit.io/usi/project-pages/01_education_1_kav14fuhhiRU.png" alt=""/>



        <img class="w-full py-4" src="https://ik.imagekit.io/usi/project-pages/03_rooftop_A_3__0o5g6GZY.png" alt=""></img>

        <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-400">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../future-foyle" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
